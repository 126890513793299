import React, { useState, useEffect } from "react";
import left_img_s3_ from "../../assets/img/element.png";
import img from "../../assets/img/Frame 20 (1).png";
import img2 from "../../assets/img/Mask group.png";
import img3 from "../../assets/img/Mask group (1).png";
import img4 from "../../assets/img/Mask group (2).png";
import img5 from "../../assets/img/Mask group (3).png";
import left_img_s2_ from "../../assets/img/bg element.png";
import Ellipse from "../../assets/img/Ellipse 86.png";
import p2 from "../../assets/img/unsplash__H6wpor9mjs.png";
import unsplash_9UVmlIb0wJU from "../../assets/img/unsplash_9UVmlIb0wJU.png";
import Group from "../../assets/img/Group 71.png";
import Dot from "../../assets/img/Dot.png";
import close_btn from "../../assets/img/Group 1000000902.png";
import p4 from "../../assets/img/p-1.png";
import p5 from "../../assets/img/p-2.png";
import p6 from "../../assets/img/p-3.png";
import p7 from "../../assets/img/p-4.png";
import p8 from "../../assets/img/p-5.png";
import p9 from "../../assets/img/p-6.png";
import p10 from "../../assets/img/p-7.png";
import p11 from "../../assets/img/p-8.png";
import aa from "../../assets/img/Ellipse 93.png";
import p1 from "../../assets/img/Image_1.png";
import p3 from "../../assets/img/Image_2.png";
import c1 from "../../assets/img/c1.png";
import c2 from "../../assets/img/c2.png";
import c3 from "../../assets/img/c3.png";
import c4 from "../../assets/img/c4.png";
import c5 from "../../assets/img/c5.png";
import c6 from "../../assets/img/c6.png";
import c7 from "../../assets/img/c7.png";
import c8 from "../../assets/img/c8.png";
// import img2 from "../../assets/img/Image_2.png";
import "./_About.scss";
import { GrFacebookOption } from "react-icons/gr";
import { AiFillInstagram } from "react-icons/ai";
import { SiTwitter } from "react-icons/si";
import { BsSkype } from "react-icons/bs";
import { VscChromeClose } from "react-icons/vsc";
import { Modal, Button } from "react-bootstrap";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
const data = [
  {
    img: c1,
    name: "Matthew Paul",
    desc: "Perfect, very good job! Thank you for the amazing design and work. Really impressed with the high quality and quick turnaround time. Highly recommend.",
  },
  {
    img: c2,
    name: "Matthew Paul2",
    desc: "2Perfect, very good job! Thank you for the amazing design and work. Really impressed with the high quality and quick turnaround time. Highly recommend.",
  },
  {
    img: c3,
    name: "Matthew Paul3",
    desc: "3Perfect, very good job! Thank you for the amazing design and work. Really impressed with the high quality and quick turnaround time. Highly recommend.",
  },
  {
    img: c4,
    name: "Matthew Paul4",
    desc: "4Perfect, very good job! Thank you for the amazing design and work. Really impressed with the high quality and quick turnaround time. Highly recommend.",
  },
  {
    img: c5,
    name: "Matthew Paul5",
    desc: "5Perfect, very good job! Thank you for the amazing design and work. Really impressed with the high quality and quick turnaround time. Highly recommend.",
  },
  {
    img: c6,
    name: "Matthew Paul6",
    desc: "6Perfect, very good job! Thank you for the amazing design and work. Really impressed with the high quality and quick turnaround time. Highly recommend.",
  },
  {
    img: c7,
    name: "Matthew Pau7",
    desc: "7Perfect, very good job! Thank you for the amazing design and work. Really impressed with the high quality and quick turnaround time. Highly recommend.",
  },
  {
    img: c8,
    name: "Matthew Paul8",
    desc: "8Perfect, very good job! Thank you for the amazing design and work. Really impressed with the high quality and quick turnaround time. Highly recommend.",
  },
];

const card_data_row1 = [
  {
    id: 1,
    name: "Gustavo Herwitz",
    post: "UI/UX Designer",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    image: p1,
    email: "Gustavo@gmail.com",
    phone: 1234567,
  },
  {
    id: 2,
    name: "Phillip Curtis",
    post: "Graphic Designer",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    image: p1,
    email: "Phillip@gmail.com",
    phone: 234568,
  },
  {
    id: 3,
    name: "Talan Torff",
    post: "Web Developer",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    image: p2,
    email: "Talan@gmail.com",
    phone: 87654,
  },
  {
    id: 4,
    name: "Abram Vaccaro",
    post: "App Developer",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    image: p3,
    email: "Abram@gmail.com",
    phone: 167789,
  },
];

const card_data_row2 = [
  {
    id: 5,
    name: "Phillip Curtis",
    post: "Graphic Designer",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    image: p4,
    email: "Phillip@gmail.com",
    phone: 8765432,
  },
  {
    id: 6,
    name: "Phillip Curtis",
    post: "Graphic Designer",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    image: p5,
    email: "Gustavo@gmail.com",
    phone: 2134554,
  },
  {
    id: 7,
    name: "Talan Torff",
    post: "Web Developer",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    image: p6,
    email: "Talan@gmail.com",
    phone: 987654,
  },
  {
    id: 8,
    name: "Abram Vaccaro",
    post: "App Developer",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    image: p7,
    email: "Abram@gmail.com",
    phone: 32400,
  },
];
const card_data_row3 = [
  {
    id: 9,
    name: "Phillip Curtis",
    post: "Graphic Designer",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    image: p8,
    email: "Phillip@gmail.com",
    phone: 8765432,
  },
  {
    id: 10,
    name: "Phillip Curtis",
    post: "Graphic Designer",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    image: p9,
    email: "Phillip@gmail.com",
    phone: 8765432,
  },
  {
    id: 11,
    name: "Phillip Curtis",
    post: "Graphic Designer",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    image: p10,
    email: "Phillip@gmail.com",
    phone: 8765432,
  },
  {
    id: 12,
    name: "Phillip Curtis",
    post: "Graphic Designer",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    image: p11,
    email: "Phillip@gmail.com",
    phone: 8765432,
  },
];
const images = [c1, c2, c3, c4, c5, c6, c7, c8];
function About() {
  const [carddata, setCarddata] = useState(card_data_row1);
  const [carddata2, setCarddata2] = useState(card_data_row2);
  const [carddata3, setCarddata3] = useState(card_data_row3);
  // console.log(carddata);
  const [popupdata, setPopupdata] = useState();
  const [popupdata2, setPopupdata2] = useState();
  const [popupdata3, setPopupdata3] = useState();
  console.log("popupdataaaa", popupdata);

  const [botton_img, setbotton_img] = useState(c1);
  const [hoverText, sethoverText] = useState(data[0]);

  const [currentIndex, setCurrentIndex] = useState(0);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (currentIndex === 7) {
  //       setCurrentIndex(0);
  //     } else {
  //       setCurrentIndex(currentIndex + 1);
  //     }
  //   }, 20000);
  setInterval(function () {
    currentIndex === 7 ? setCurrentIndex(0) : setCurrentIndex(currentIndex + 1);
  }, 5000);
  //   return () => clearInterval(intervalId);
  // }, []);
  return (
    <>
      <NavBar />
      <div>
        <div className="">
          <div className="container-fluid main_">
            <div className="container">
              <div className="row" style={{ padding: "120px 0 0 0" }}>
                <div className="col-12 col-lg-6 order-1 d-flex justify-content-center align-items-center">
                  <div className="right_div">
                    <h1>About Us</h1>
                    <p className="">
                      Build and grow your affiliate partnerships by discovering
                      what works best for your competitors, reveal the most
                      valuable partners with the highest relevance to your offer
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 order-2 d-flex justify-content-center align-items-center">
                  <img src={img} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="a-2">
          <img src={left_img_s2_} alt="" className="img-fluid left_img_2" />
          <div className="container">
            <div className="row gy-5" style={{ padding: "80px 0" }}>
              <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                <div className="img_div_1">
                  <img src={img2} alt="" className="img-fluid" />
                  <img src={Ellipse} alt="" className="img-fluid back_img" />
                </div>
              </div>
              <div className="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-center">
                <h1>Great Digital Product Agency since 2016 </h1>
                <p>
                  Our Business Plan is a written document describing a company's
                  core business activites, Objectives, and how it plans to
                  achieve its goals. Our goal is to provide our client high
                  quality Product with modern idea accordingly their budgets and
                  according thir reuirements.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="a-3">
          <img src={left_img_s3_} alt="" className="img-fluid left_img_3" />
          <div className="container">
            <h1>Meet Our Team</h1>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable <br /> content of a page when looking at its layout.
            </p>
            <div className="row mt-4 card-row">
              {carddata.map((data, i) => {
                return (
                  <div className="col-12 col-md-6 col-lg-3 mt-3" key={i}>
                    <div className="d-flex justify-content-center align-items-center flex-column box ">
                      <div className="d-flex justify-content-center align-items-center flex-column box1 ">
                        <h5>{data.name}</h5>
                        <p>{data.post}</p>
                        <p className="mb-3 px-2">{data.content}</p>
                        <div className="icons">
                          <div className="icon">
                            <GrFacebookOption />
                          </div>
                          <div className="icon">
                            <AiFillInstagram />
                          </div>
                          <div className="icon">
                            <SiTwitter />
                          </div>
                          <div className="icon">
                            <BsSkype />
                          </div>
                        </div>
                        <a
                          className="text-white mt-2"
                          onClick={() => {
                            setPopupdata(data);
                            setPopupdata3();
                            setPopupdata2();
                          }}
                        >
                          View Profile
                        </a>
                      </div>
                      <img src={data.image} alt="" className="img-fluid" />
                      <h5>{data.name}</h5>
                      <p>{data.post}</p>
                      <a href="">View Profile</a>
                    </div>
                  </div>
                );
              })}

              {popupdata && (
                <div className="popup mt-5">
                  <div className="row ">
                    <div className="col-12 col-xl-5  d-flex flex-column align-items-center justify-content-center">
                      <div className="client-pic w-100 d-flex align-items-center justify-content-center">
                        <img src={popupdata.image} className="c-pic" />
                      </div>
                      <div className="icons mt-3">
                        <div className="icon">
                          <GrFacebookOption />
                        </div>
                        <div className="icon">
                          <AiFillInstagram />
                        </div>
                        <div className="icon">
                          <SiTwitter />
                        </div>
                        <div className="icon">
                          <BsSkype />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-xl-7  c-info ">
                      <div className="d-flex flex-column align-items-start">
                        <h5 className="m-0 c-name">{popupdata.name}</h5>
                        <p className="mt-2 c-post">{popupdata.post}</p>
                      </div>

                      <div className="content">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.It is a long established fact that
                        a reader will be distracted by the readable content of a
                        page when looking at its layout.
                      </div>
                      <div className="content">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.It is a long established fact that
                        a reader will be distracted by the readable content of a
                        page when looking at its layout.
                      </div>
                      <div className="row w-100">
                        <div className="col-4 email">Email Address</div>
                        <div className="col-8 content">{popupdata.email}</div>
                        <div className="col-4 mt-3 phone">Phone No.</div>
                        <div className="col-8 mt-3 content">
                          {popupdata.phone}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="close-icon" onClick={() => setPopupdata()}>
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 1.60649L11.3935 0L6.5 4.89351L1.60649 0L0 1.60649L4.89351 6.5L0 11.3935L1.60649 13L6.5 8.10649L11.3935 13L13 11.3935L8.10649 6.5L13 1.60649Z"
                        fill="#948F8F"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>
            <div className="row mt-4 card-row">
              {carddata2.map((data, i) => {
                return (
                  <div className="col-12 col-md-6 col-lg-3 mt-3" key={i}>
                    <div className="d-flex justify-content-center align-items-center flex-column box ">
                      <div className="d-flex justify-content-center align-items-center flex-column box1 ">
                        <h5>{data.name}</h5>
                        <p>{data.post}</p>
                        <p className="mb-3 px-2">{data.content}</p>
                        <div className="icons">
                          <div className="icon">
                            <GrFacebookOption />
                          </div>
                          <div className="icon">
                            <AiFillInstagram />
                          </div>
                          <div className="icon">
                            <SiTwitter />
                          </div>
                          <div className="icon">
                            <BsSkype />
                          </div>
                        </div>
                        <a
                          className="text-white mt-2"
                          onClick={() => {
                            setPopupdata2(data);
                            setPopupdata3();
                            setPopupdata();
                          }}
                        >
                          View Profile
                        </a>
                      </div>
                      <img src={data.image} alt="" className="img-fluid" />
                      <h5>{data.name}</h5>
                      <p>{data.post}</p>
                      <a href="">View Profile</a>
                    </div>
                  </div>
                );
              })}

              {popupdata2 && (
                <div className="popup mt-5">
                  <div className="row ">
                    <div className="col-12 col-xl-5  d-flex flex-column align-items-center justify-content-center">
                      <div className="client-pic w-100 d-flex align-items-center justify-content-center">
                        <img src={popupdata2.image} className="c-pic" />
                      </div>
                      <div className="icons mt-3">
                        <div className="icon">
                          <GrFacebookOption />
                        </div>
                        <div className="icon">
                          <AiFillInstagram />
                        </div>
                        <div className="icon">
                          <SiTwitter />
                        </div>
                        <div className="icon">
                          <BsSkype />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-xl-7  c-info ">
                      <div className="d-flex flex-column align-items-start">
                        <h5 className="m-0 c-name">{popupdata2.name}</h5>
                        <p className="mt-2 c-post">{popupdata2.post}</p>
                      </div>

                      <div className="content">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.It is a long established fact that
                        a reader will be distracted by the readable content of a
                        page when looking at its layout.
                      </div>
                      <div className="content">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.It is a long established fact that
                        a reader will be distracted by the readable content of a
                        page when looking at its layout.
                      </div>
                      <div className="row w-100">
                        <div className="col-4 email">Email Address</div>
                        <div className="col-8 content">{popupdata2.email}</div>
                        <div className="col-4 mt-3 phone">Phone No.</div>
                        <div className="col-8 mt-3 content">
                          {popupdata2.phone}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="close-icon" onClick={() => setPopupdata2()}>
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 1.60649L11.3935 0L6.5 4.89351L1.60649 0L0 1.60649L4.89351 6.5L0 11.3935L1.60649 13L6.5 8.10649L11.3935 13L13 11.3935L8.10649 6.5L13 1.60649Z"
                        fill="#948F8F"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>
            <div className="row mt-4 card-row">
              {carddata3.map((data, i) => {
                return (
                  <div className="col-12 col-md-6 col-lg-3 mt-3" key={i}>
                    <div className="d-flex justify-content-center align-items-center flex-column box ">
                      <div className="d-flex justify-content-center align-items-center flex-column box1 ">
                        <h5>{data.name}</h5>
                        <p>{data.post}</p>
                        <p className="mb-3 px-2">{data.content}</p>
                        <div className="icons">
                          <div className="icon">
                            <GrFacebookOption />
                          </div>
                          <div className="icon">
                            <AiFillInstagram />
                          </div>
                          <div className="icon">
                            <SiTwitter />
                          </div>
                          <div className="icon">
                            <BsSkype />
                          </div>
                        </div>
                        <a
                          className="text-white mt-2"
                          onClick={() => {
                            setPopupdata3(data);
                            setPopupdata2();
                            setPopupdata();
                          }}
                        >
                          View Profile
                        </a>
                      </div>
                      <img src={data.image} alt="" className="img-fluid" />
                      <h5>{data.name}</h5>
                      <p>{data.post}</p>
                      <a href="">View Profile</a>
                    </div>
                  </div>
                );
              })}

              {popupdata3 && (
                <div className="popup mt-5">
                  <div className="row ">
                    <div className="col-12 col-xl-5  d-flex flex-column align-items-center justify-content-center">
                      <div className="client-pic w-100 d-flex align-items-center justify-content-center">
                        <img src={popupdata3.image} className="c-pic" />
                      </div>
                      <div className="icons mt-3">
                        <div className="icon">
                          <GrFacebookOption />
                        </div>
                        <div className="icon">
                          <AiFillInstagram />
                        </div>
                        <div className="icon">
                          <SiTwitter />
                        </div>
                        <div className="icon">
                          <BsSkype />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-xl-7  c-info ">
                      <div className="d-flex flex-column align-items-start">
                        <h5 className="m-0 c-name">{popupdata3.name}</h5>
                        <p className="mt-2 c-post">{popupdata3.post}</p>
                      </div>

                      <div className="content">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.It is a long established fact that
                        a reader will be distracted by the readable content of a
                        page when looking at its layout.
                      </div>
                      <div className="content">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.It is a long established fact that
                        a reader will be distracted by the readable content of a
                        page when looking at its layout.
                      </div>
                      <div className="row w-100">
                        <div className="col-4 email">Email Address</div>
                        <div className="col-8 content">{popupdata3.email}</div>
                        <div className="col-4 mt-3 phone">Phone No.</div>
                        <div className="col-8 mt-3 content">
                          {popupdata3.phone}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="close-icon" onClick={() => setPopupdata3()}>
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 1.60649L11.3935 0L6.5 4.89351L1.60649 0L0 1.60649L4.89351 6.5L0 11.3935L1.60649 13L6.5 8.10649L11.3935 13L13 11.3935L8.10649 6.5L13 1.60649Z"
                        fill="#948F8F"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="a-6">
          <img src={left_img_s2_} alt="" className="img-fluid left_img_2" />
          <div className="container">
            <h1>What our happy client say</h1>
            <p>Several selected clients, who already believe in our service.</p>
            <div className="row gy-4 g-lg-0 justify-content-center">
              <div className="col-12 col-md-6 col-lg-4 left_img_bottom img_div">
                <div className="large-img bg-danger">
                  <img src={botton_img} alt="" className="img-fluid c-img" />
                  <img src={Ellipse} alt="" className="img-fluid back_img" />
                  <img src={Dot} alt="" className="img-fluid right_img_" />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 left_img_bottom2">
                <h5>{hoverText.name}</h5>
                <span>{hoverText.desc}</span>
              </div>
              <div className="col-12 col-md-6 col-lg-4 right_img_bottom">
                <div className="bottom_img">
                  {data.map((item, index) => {
                    return (
                      <img
                        key={index}
                        src={item.img}
                        alt=""
                        className={`img-fluid c${index + 1}`}
                        onMouseMove={() => {
                          sethoverText(item);
                          setbotton_img(item.img);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
