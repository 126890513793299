import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import not_active from "../../assets/img/not_active.png";
import active from "../../assets/img/fluent_arrow-step-in-12-regular (1).png";
import "./_Accodian.scss";
function Accodian({ tital, item }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="container">
      <div className="accordion2 my-4">
        <div
          className="main d-flex justify-content-between align-items-center"
          onClick={() => setExpanded(!expanded)}
        >
          <h6 className="ml-2 accordion-title2">{tital}</h6>
          <span>
            {expanded ? (
              <img src={active} alt="" width="35px" height="35px" />
            ) : (
              <img src={not_active} alt="" width="35px" height="35px" />
            )}
          </span>
        </div>
        <div className={`des ${!expanded ? "hide" : "show"}`}>
          <div>
            {item}
            {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate consequuntur eveniet accusantium aliquam. Laudantium neque ea provident libero autem excepturi, expedita praesentium natus fugit, nisi corporis ab tempore dicta veniam. */}
          </div>
        </div>
      </div>
      {/* <h1>{tital}</h1>
      <p>{des}</p> */}
    </div>
  );
}

export default Accodian;
