import React from "react";
import "./_Home.scss";
import img from "../../assets/img/Contact us-rafiki 1.png";
import said_img from "../../assets/img/said_img.png";
import left_img_s2 from "../../assets/img/Group 1000000935.png";
import said_img_s2 from "../../assets/img/Group 1000000936.png";
import left_img_s2_ from "../../assets/img/bg element.png";
import left_img_s2_2 from "../../assets/img/file-icons_leaflet.png";
import left_img_s3_ from "../../assets/img/element.png";
import left_img_s3 from "../../assets/img/Group 1000000980.png";
import left_img_s3_2 from "../../assets/img/Group 1000000920.png";
import fb from "../../assets/img/Group 1000000966.png";
import tw from "../../assets/img/Group 1000000967.png";
import p from "../../assets/img/Group 1000000968.png";
import inta from "../../assets/img/Group 1000000969.png";
import { HiLocationMarker } from "react-icons/hi";
import { IoCallSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import Accodian from "../../components/Accodian/Accodian";
import { Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";

function Home() {
  const Acc = [
    {
      tital:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      des: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    },
    {
      tital:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      des: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    },
    {
      tital:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      des: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    },
    {
      tital:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      des: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    },
    {
      tital:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      des: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    },
    {
      tital:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      des: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    },
    {
      tital:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      des: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    },
    {
      tital:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      des: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    },
  ];
  return (
    <>
      <NavBar />
      <div className="">
        <div className="container-fluid main_">
          <img src={said_img} alt="" className="img-fluid right_img" />
          <img src={said_img} alt="" className="img-fluid left_img" />
          <div className="container">
            <div className="row" style={{ padding: "65px 0" }}>
              <div className="col-12 col-lg-6 order-1 d-flex justify-content-center align-items-center">
                <div className="right_div">
                  <h1>Contact Us</h1>
                  <p className="">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 order-2 d-flex justify-content-center align-items-center">
                <img src={img} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div className="s-2">
          <div className="container-fluid ">
            <img src={left_img_s2} alt="" className="img-fluid right_img" />
            <img src={said_img_s2} alt="" className="img-fluid left_img" />
            <img src={left_img_s2_} alt="" className="img-fluid left_img_2" />
            <img src={left_img_s2_2} alt="" className="img-fluid right_img_2" />
            <img src={said_img} alt="" className="img-fluid right_img_2_2" />

            <h1>Let’s talk about your idea</h1>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable <br /> content of a page when looking at its layout.
            </p>
            <Container>
              <div className="main_box">
                <Row className="p-0 m-0">
                  <div className="col-12 col-lg-7">
                    <div className="row">
                      <div className="col-12 col-lg-6 py-2">
                        <div className="input_main d-flex flex-column">
                          <label htmlFor="">Name</label>
                          <input type="text" placeholder="Enter your name" />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 py-2">
                        <div className="input_main d-flex flex-column">
                          <label htmlFor="">Email</label>
                          <input
                            type="text"
                            placeholder="Enter your email address"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 py-2">
                        <div className="input_main d-flex flex-column">
                          <label htmlFor="">Phone Number</label>
                          <input
                            type="text"
                            placeholder="Enter your phone number"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 py-2">
                        <div className="input_main d-flex flex-column">
                          <label htmlFor="">Subject</label>
                          <input type="text" placeholder="Enter your subject" />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 py-2">
                        <div className="input_main d-flex flex-column">
                          <label htmlFor="">Message</label>
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="8"
                            placeholder="Enter your subject"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <button>Submit</button>
                  </div>
                  <div className="col-12 col-lg-5">
                    <div className="row g-3">
                      <div className="col-lg-12 col-md-6">
                        <div className="left_said">
                          <h5 className=""> Office Location</h5>
                          <div className="d-flex py-2 ms-lg-3">
                            <div className="icon_main">
                              <div className="icon">
                                <HiLocationMarker />
                              </div>
                            </div>
                            <div className="icon_main_text">
                              <p className="icon_text">
                                22 Baker Street, London, United Kingdom, W1U 3BW
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="left_said">
                          <h5 className=""> Phone Number</h5>
                          <div className="d-flex py-2 ms-lg-3">
                            <div className="icon_main">
                              <div className="icon">
                                <IoCallSharp />
                              </div>
                            </div>
                            <div className="icon_main_text">
                              <p className="icon_text">+44-20-7328-4499</p>
                              <p className="icon_text ">+99-34-8878-9989</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="left_said">
                          <h5 className=""> Email Address</h5>
                          <div className="d-flex py-2  ms-lg-3">
                            <div className="icon_main">
                              <div className="icon">
                                <MdEmail />
                              </div>
                            </div>
                            <div className="icon_main_text">
                              <p className="icon_text">info@yourdomain.com </p>
                              <p className="icon_text">admin@yourdomain.com </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="left_said ">
                          <h5 className="">Social Address</h5>
                          <div className="d-flex py-2 ms-lg-3">
                            <img
                              src={fb}
                              alt=""
                              className="img-fluid me-1 me-sm-2"
                            />
                            <img
                              src={tw}
                              alt=""
                              className="img-fluid mx-1 mx-sm-2"
                            />
                            <img
                              src={p}
                              alt=""
                              className="img-fluid mx-1 mx-sm-2"
                            />
                            <img
                              src={inta}
                              alt=""
                              className="img-fluid mx-1 mx-sm-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </Container>
          </div>
        </div>
        <div className="s-3">
          <img src={left_img_s3} alt="" className="img-fluid left_img_3_1" />
          <img src={left_img_s3_} alt="" className="img-fluid left_img_3" />
          <img src={left_img_s3_2} alt="" className="img-fluid left_img_3_2" />
          <img src={left_img_s3_2} alt="" className="img-fluid right_img_3_2" />
          <img src={left_img_s2_2} alt="" className="img-fluid right_img_3_3" />
          <img src={said_img} alt="" className="img-fluid right_img_3_4" />

          <div className="container-fluid ">
            <h1>Frequently Asked Questions</h1>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable <br /> content of a page when looking at its layout.
            </p>
            <div className="pt-5">
              {Acc.map((e) => {
                return <Accodian tital={e.tital} item={e.des} />;
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
