import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import NavBar from "../src/components/NavBar/NavBar";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import About from "./pages/About/About";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      {/* <NavBar /> */}
      {/* <Home /> */}

      {/* <About /> */}
      {/* <Footer /> */}
      <BrowserRouter>
        <Routes>
          <Route  exact path="/" element={<Home />}></Route>
          <Route exact path="/about" element={<About />}></Route>
        
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
