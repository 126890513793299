import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Logo from "../../assets/img/main-dev-logo.png";
import "./_navbar.scss";
import close_btn from "../../assets/img/Group 1000000902.png";
import menu_1 from "../../assets/img/menu_1.png";
import menu_2 from "../../assets/img/menu_2.png";
import menu_3 from "../../assets/img/menu_3.png";
import menu_4 from "../../assets/img/menu_4.png";
import menu_5 from "../../assets/img/menu_5.png";
import menu_6 from "../../assets/img/menu_6.png";

export default function NavBar() {
  return (
    <>
      <Navbar expand="lg" fixed="top">
        <Container className="px-xl-5 px-2xl-0">
          <Navbar.Brand href="#home" className="nav-logo-wrapper">
            <img src={Logo} alt="" className="img-fluid" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <div className="main">
                <Nav.Link href="/about">About Us</Nav.Link>
                <div className="sub_div">
                  <img src={close_btn} alt="" className="img-fluid img" />
                  <div className="pt-4">
                    <div className="row">
                      <div className="col-12 col-md-5">
                        <div className="d-flex py-2 data">
                          <img src={menu_1} alt="" className="img-fluid px-3" />
                          <span>Overview</span>
                        </div>
                        <div className="d-flex py-2 data">
                          <img src={menu_3} alt="" className="img-fluid px-3" />
                          <span>Diversity</span>
                        </div>
                        <div className="d-flex py-2 data">
                          <img src={menu_5} alt="" className="img-fluid px-3" />
                          <span>Sustainability</span>
                        </div>
                      </div>
                      <div className="col-12 col-md-7">
                        <div className="d-flex py-2 data">
                          <img src={menu_2} alt="" className="img-fluid px-3" />
                          <span>Social Responsibility</span>
                        </div>
                        <div className="d-flex py-2 data">
                          <img src={menu_4} alt="" className="img-fluid px-3" />
                          <span>Media</span>
                        </div>
                        <div className="d-flex py-2 data">
                          <img src={menu_6} alt="" className="img-fluid px-3" />
                          <span>Alumni</span>
                        </div>
                      </div>
                    </div>
                    <div className="bottom_div">
                      <h1>Featured</h1>
                      <p>
                        Inspire, empower, and sustain action that leads to the
                        economic development of Black communicaties across the
                        globe.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Nav.Link href="/">Our App</Nav.Link>
              <Nav.Link href="/">Contact</Nav.Link>
              {/* <Nav.Link href="/">Log in</Nav.Link> */}
              {/* <div href="/" className='nav__signup'>
                <span> Sign Up</span>
              </div> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
