import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../assets/img/main-dev-logo.png";
import "./_footer.scss";

import {
  AiOutlineInstagram,
  AiOutlineDribbble,
  AiOutlineTwitter,
  AiFillYoutube,
} from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import { FiSmartphone } from "react-icons/fi";
import { BiMap } from "react-icons/bi";

export default function Footer() {
  return (
    <>
      <div className="footer__wrapper">
        <Container>
          <Row className="gy-5">
            <Col xs={12} md={6} lg={3}>
              <div className="footer__colum-wrapper">
                <div>
                  <img src={Logo} alt="" className="img-fluid" />
                </div>
                <div className="footer-description">
                  Lorem ipsum dolor amet, consectetur adipiscing elit. Eget nisl
                  nunc quam ac sed turpis volutpat. Cursus sed massa non nisi,
                  placerat.
                </div>
                <div className="footer__social-wrappr">
                  <a className="footer__social-icon" href="/">
                    <AiOutlineInstagram />
                  </a>
                  <a className="footer__social-icon" href="/">
                    <AiOutlineDribbble />
                  </a>
                  <a className="footer__social-icon" href="/">
                    <AiOutlineTwitter />
                  </a>
                  <a className="footer__social-icon" href="/">
                    <AiFillYoutube />
                  </a>
                </div>
              </div>
            </Col>

            <Col xs={12} md={6} lg={3}>
              <div className="footer__colum-wrapper">
                <div className="footer__colum-heading">Quick Links</div>

                <ul className="footer__colum-link">
                  <li>
                    <a href="/">Portfolio</a>
                  </li>
                  <li>
                    <a href="/">Blogs</a>
                  </li>
                  <li>
                    <a href="/">About</a>
                  </li>
                  <li>
                    <a href="/">Press</a>
                  </li>
                  <li>
                    <a href="/">Careers</a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col xs={12} md={6} lg={3}>
              <div className="footer__colum-wrapper">
                <div className="footer__colum-heading">Services</div>

                <ul className="footer__colum-link">
                  <li>
                    <a href="/">UX/UI Design</a>
                  </li>
                  <li>
                    <a href="/">App Development</a>
                  </li>
                  <li>
                    <a href="/">Web Development</a>
                  </li>
                  <li>
                    <a href="/">Quality Assurance</a>
                  </li>
                  <li>
                    <a href="/">Machine Learning</a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col xs={12} md={6} lg={3}>
              <div className="footer__colum-wrapper">
                <div className="footer__colum-heading">Reach us</div>

                <ul className="footer__colum-link">
                  <li>
                    <HiOutlineMail />
                    <a href="mailto:hello@landify.co">hello@landify.co</a>
                  </li>
                  <li>
                    <FiSmartphone />
                    <a href="tel:+919876543210">+91 98765 43210</a>
                  </li>
                  <li>
                    <BiMap />
                    <a href="https://goo.gl/maps/kyb2zWG4N3jFGB4y5">
                      772 Lyonwood Ave <br />
                      Walnut, CA 91789
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="footer__copyright-wrapper">
        <Container>
          <div className="footer__copyright">
            <div className="copyright-text">
              © 2020 Lorem Ipsum. All rights reserved
            </div>
            <div className="footer-link">
              <a href="/">Terms &#38; Conditions</a>|
              <a href="/">Privacy Policy</a>|<a href="/">Sitemap</a>|
              <a href="/">Disclaimer</a>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
